import { REL_TYPE_TO_ONE } from "./constants";

const call = {
  attributes: {
    id: { editable: false },
    abbreviation: { editable: true },
    additionalFeeInfo: { editable: true },
    additionalRequirementsInfo: { editable: true },
    allowsRevocation: { editable: true },
    backgroundColor: { editable: true },
    confirmationMailText: { editable: true },
    confirmationMailTitle: { editable: true },
    contact: { editable: true },
    currency: { editable: true },
    customProfileFields: { editable: true },
    customFeeDescription: { editable: true },
    customFeeDescriptionShort: { editable: true },
    description: { editable: true },
    faqs: { editable: true },
    faqLink: { editable: true },
    fee: { editable: true },
    sendStripeReceipt: { editable: true },
    gallery: { editable: true },
    isFeeVoluntary: { editable: true },
    logoDefault: { editable: true },
    logoEmail: { editable: true },
    logoSubmissionWizardBranding: { editable: true },
    maxProjects: { editable: true },
    primaryColor: { editable: true },
    primaryText: { editable: true },
    profileRequirements: { editable: true },
    hiddenProfileFields: { editable: true },
    secondaryText: { editable: true },
    showInvoice: { editable: true },
    slogan: { editable: true },
    slug: { editable: true },
    submissionEndDate: { editable: true },
    submissionIntroduction: { editable: true },
    submissionStartDate: { editable: true },
    successfulSubmissionMessage: { editable: true },
    successfulSubmissionTitle: { editable: true },
    teaserCaption: { editable: true },
    teaserImage: { editable: true },
    termsAndConditions: { editable: true },
    timeline: { editable: true },
    title: { editable: true },
    video: { editable: true },
    videoTitle: { editable: true },
    visibilityStatus: { editable: true },
    useNewSubmissionWizard: { editable: true },
    website: { editable: true },
    transactionFee: { editable: true },
    baseTransactionFee: { editable: true },
    contestListTeaser: { editable: true },
    customNewsletterSignup1: { editable: true },
    customNewsletterSignup2: { editable: true },
    forbidDuplicates: { editable: true },
    submissionBufferTime: { editable: true },
    hasCoordinatorMode: { editable: true },
  },
  relationships: {
    organisation: {
      type: "organisations",
      editable: ["create"],
      relation: REL_TYPE_TO_ONE,
    },
  },
};

export default call;
